<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialogs" :confirmDialog='importConfirm' :isdeling='isdeling'>
    <!-- <el-upload
      class="upload-demo"
      accept=".pdf,.ofd"
      ref="upload"
      action="#"
      drag
      multiple
      :on-change="handleChange"
      :before-upload='beforeUpload'
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      :auto-upload="false"
      :http-request="httpRequest"
      >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        将文件拖到此处，或<em>点击上传</em>
        <p>支持扩展名：PDF，OFD</p>
      </div>
    </el-upload> -->
    <div class="example-drag">

            <!-- post-action="/upload/post" -->
          <file-upload
            class="upload"
            accept=".pdf,.ofd"
            :multiple="true"
            :drop="true"
            :drop-directory="true"
            v-model="fileList"
            @input="updatetValue"
            @input-filter="inputFilter"
            ref="upload">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件、文件夹拖到此处，或<em>点击上传</em>
              <p>支持扩展名：PDF、OFD</p>
            </div>
          </file-upload>

          <ul v-if="fileList.length" class="showList">
            <li v-for="file in fileList" :key="file.id">
              <span>{{file.name}}</span>
              <div class="remove" @click.prevent="$refs.upload.remove(file)">X</div>
              <!-- <span>{{file.size}}</span> -->
              <!-- <span v-if="file.error">{{file.error}}</span>
              <span v-else-if="file.success">success</span>
              <span v-else-if="file.active">active</span> -->
              <!-- <span v-else></span> -->
            </li>
          </ul>

          <p class="tips" :class="{show: message != '' }">{{message}}</p>

          <!-- 拖拽上传提示 -->
          <!-- <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
            <h3>Drop fileList to upload</h3>
          </div> -->

    </div>

  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  import FileUpload from 'vue-upload-component'
  import axios from "axios";
  import XLSX from "xlsx";
  import { employImport } from '@/api/employInfo'

  export default {
    props: {
      visible: {
        type: Boolean,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {
      Dialog,
      FileUpload,
    },
    data() {
      return {
        config: {
          top: '20vh',
          width: '21.2rem',
          title: '发票导入',
          center: true,
          btnTxt: ['导入', '取消'],
        },
        fileList: [],
        fileData: [],
        isdeling: false,
        tableData: [],
        status: false,
        message: '',
        invoiceType:'1'
      };
    },
    methods: {
      updatetValue(value) {  //文件上传状态更改
        this.fileList = value;
        console.log(value,'上传的全部文件');
        console.log(value,'上传的全部文件');
      },
      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          const isLt10M = newFile.size / 1024 / 1024 < 10;
          // const isLt1M = newFile.size / 1024 / 1024 < 1;
        // 添加文件
        // 过滤非图片文件
        // 不会添加到 files 去
          if (!/\.(pdf|ofd|jpg|png|jpeg)$/i.test(newFile.name)) {  //过滤文件
            this.message = '上传文件只能是pdf或ofd格式！'
            // einvAlert.error("提示",this.message);
            return prevent()
          }
          if (!isLt10M) {  //过滤文件
            this.message = '上传文件大小不能超过 10MB!'
            // einvAlert.error("提示",this.message);
            return prevent()
          }
        }

      if (newFile && oldFile) {
        // 更新文件
        // 增加版本号
        if (!newFile.version) {
          newFile.version = 0
        }
        newFile.version++
      }

      if (!newFile && oldFile) {
        // 移除文件
        // 拒绝删除文件
        return prevent()
      }
    },
      cancelDialogs() {
        this.cancelDialog();
        this.fileList = [];
        this.fileData = '';
        this.status = false;
        this.message = '';
      },
      importConfirm() {
        this.isdeling = true
        let formData = new FormData(); // 批量上传
        //遍历数据，手动注入formData
        for (let i = 0; i < this.fileList.length; i++) {
            formData.append("files", this.fileList[i].file);
        }
        formData.append("companyId", this.getToken('companyId'));
        formData.append("userId", this.getToken('userId'));
        formData.append('type',this.invoiceType)
        console.log(formData.getAll("files"));

        // this.fileList?.map((item)=>{  //以批量上传
        //   let fd = new FormData()
        //   fd.append('file', item.file)
        //   fd.append('asId', this.getToken('asId'))
        //   fd.append('userId', this.getToken('userId'))
        //   employImport(fd).then(res => { // 以文件传输格式 单个 file: (binary) 进行上传
        //     console.log(fd);
        //     if (res.success) {}
        //   })
        // })
        // console.log(this.fileData);

        if (this.fileList && this.fileList.length > 0) {
          this.$store.dispatch("BatchUpload",formData).then(res => { // 以文件传输格式 file: (binary) 进行上传
          // employImport(formData).then(res => { // 以文件传输格式 file: (binary) 进行上传
              console.log(formData);
            let  _this=this;
              if (res.success && res.data.isSuccess) {
                this.isdeling = false
                einvAlert.success("导入成功","导入成功"+res.data.successCount+"张");
              }else if(res.success){
                this.isdeling = false
                einvAlert.confirm(function (){
                      _this.uploadError(res.data.logId)
                    },"<span>导入成功"+res.data.successCount+"张</span>,"+"<span style='color: red'>导入失败"+res.data.failCount+"张</span>",
                '下载错误记录','取消');
              }else{
                this.isdeling = false
                einvAlert.error("错误",res.msg);
              }
              this.$parent.importVis = false
              this.fileList = []
              this.$parent.getList()
            }).catch(err => {
              this.isdeling = false
          })
        }else{
          this.isdeling = false
          this.message = '请选择上传文件';
        }
      },

      json2Excel(dataSource) {
        var wopts = {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        };
        var workBook = {
          SheetNames: ['Sheet1'],
          Sheets: {},
          Props: {}
        };
        //1、XLSX.utils.json_to_sheet(data) 接收一个对象数组并返回一个基于对象关键字自动生成的“标题”的工作表，默认的列顺序由使用Object.keys的字段的第一次出现确定
        //2、将数据放入对象workBook的Sheets中等待输出
        workBook.Sheets['Sheet1'] = XLSX.utils.json_to_sheet(dataSource)
        //3、XLSX.write() 开始编写Excel表格
        //4、changeData() 将数据处理成需要输出的格式
        this.saveAs(new Blob([this.changeData(XLSX.write(workBook, wopts))], { type: 'application/octet-stream' }), "错误信息")
      },
      changeData(s) {
        //如果存在ArrayBuffer对象(es6) 最好采用该对象
        if (typeof ArrayBuffer !== 'undefined') {
          //1、创建一个字节长度为s.length的内存区域
          var buf = new ArrayBuffer(s.length);
          //2、创建一个指向buf的Unit8视图，开始于字节0，直到缓冲区的末尾
          var view = new Uint8Array(buf);
          //3、返回指定位置的字符的Unicode编码
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        } else {
          var buf = new Array(s.length);
          for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
      },
      saveAs(obj, fileName) {//当然可以自定义简单的下载文件实现方式
        var tmpa = document.createElement("a");
        tmpa.download = fileName ? fileName + '.xls' : new Date().getTime() + '.xls';
        tmpa.href = URL.createObjectURL(obj); //绑定a标签
        tmpa.click(); //模拟点击实现下载
        setTimeout(function () { //延时释放
          URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
        }, 100);
      },
      uploadError(val){
        this.isdeling = true
        axios({
          method: 'get',
          url: '/api/euser/log/downloadAttachment',
          responseType: 'blob',
          params: {
            logId : val
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let fileReader = new FileReader();
          fileReader.onload = function() {
            try {
              let jsonData = JSON.parse(this.result);  // 说明是普通对象数据，后台转换失败
              if (jsonData.status == '40301') { // 接口返回的错误信息
                einvAlert.error("错误",jsonData.message) // 弹出的提示信息
              }
            } catch (err) {   // 解析成对象失败，说明是正常的文件流
              let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url;
              var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
              link.setAttribute('download', '发票导入失败数据' + now + '.xls')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          };
          fileReader.readAsText(data)  // 注意别落掉此代码，可以将 Blob 或者 File 对象转根据特殊的编码格式转化为内容(字符串形式)
          this.isdeling = false
        }).catch(err=>{
          this.isdeling = false
         // einvAlert.error("提示",err)
        })
      }

    },
    watch:{
      message(val){ //状态提示 显隐
        if(val){
          setTimeout(()=>{
            this.message = ''
          },1500)
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .upload{
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    height: 180px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-icon-upload {
    font-size: 67px;
    color: #C0C4CC;
    margin: 30px 0 16px;
    line-height: 50px;
  }
  .el-upload__text{
    em{
      font-style: inherit;
      cursor: pointer;
      color: #409EFF;
    }
    p{
      margin: 10px 0;
    }
  }
  .showList{
    margin: 6px 2px;
    padding: 0;
    max-height: 100px;
    overflow-y: scroll;
    li{
      display: flex;
      list-style: none;
      margin: 4px 0px;
      justify-content: space-between;
      span{
        cursor: pointer;
      }
      .remove{
        cursor: pointer;
      }
      .remove:hover{
        color: #409EFF;
      }
    }
  }

.tips{
  color: #FF0000;
  padding: 0;
  margin: 0;
}
.show {
  animation: hide 1s ease;
}
@keyframes hide {
  0% {opacity: 0;}
  100% {opacity: 0.9;}
}

</style>
